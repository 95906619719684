import React, {PropsWithChildren} from 'react';
import {makeStyles} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import WebAsset from '@material-ui/icons/WebAsset';
import FolderIcon from '@material-ui/icons/Folder';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {Settings as SidebarSettings, UserSettingsSignInAvatar,} from '@backstage/plugin-user-settings';
import {SidebarSearchModal} from '@backstage/plugin-search';
import {
    Link,
    Sidebar,
    sidebarConfig,
    SidebarDivider,
    SidebarGroup,
    SidebarItem,
    SidebarPage,
    SidebarScrollWrapper,
    SidebarSpace,
    useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import {MyGroupsSidebarItem} from '@backstage/plugin-org';
import GroupIcon from '@material-ui/icons/People';

const useSidebarLogoStyles = makeStyles({
    root: {
        width: sidebarConfig.drawerWidthClosed,
        height: 3 * sidebarConfig.logoHeight,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        marginBottom: -14,
    },
    link: {
        width: sidebarConfig.drawerWidthClosed,
        marginLeft: 24,
    },
});

const SidebarLogo = () => {
    const classes = useSidebarLogoStyles();
    const {isOpen} = useSidebarOpenState();

    return (
        <div className={classes.root}>
            <Link to="/" underline="none" className={classes.link} aria-label="Home">
                {isOpen ? <LogoFull/> : <LogoIcon/>}
            </Link>
        </div>
    );
};

export const Root = ({children}: PropsWithChildren<{}>) => (
    <SidebarPage>
        <Sidebar>
            <SidebarLogo/>
            <SidebarGroup label="Search" icon={<SearchIcon/>} to="/search">
                <SidebarSearchModal/>
            </SidebarGroup>
            <SidebarDivider/>
            <SidebarGroup label="Menu" icon={<MenuIcon/>}>
                {/* Global nav, not org-specific */}
                <SidebarItem icon={HomeIcon} to="catalog" text="Home"/>
                <MyGroupsSidebarItem
                    singularTitle="My Group"
                    pluralTitle="My Groups"
                    icon={GroupIcon}
                />
                <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs"/>
                <SidebarItem icon={LibraryBooks} to="docs/default/component/digital-docs" text="Digital Docs"/>
                <SidebarItem icon={WebAsset} to="docs/default/component/digitalekanaler-main-frontend" text="Web Docs"/>
                <SidebarItem icon={PhoneIphone} to="docs/default/component/salgsapp-react-native" text="App Docs"/>
                <SidebarItem icon={FolderIcon} to="docs" text="Tech Docs"/>
                <SidebarItem icon={CreateComponentIcon} to="create" text="Create..."/>
                {/* End global nav */}
                <SidebarDivider/>
                <SidebarScrollWrapper>
                    {/* Items in this group will be scrollable if they run out of space */}
                </SidebarScrollWrapper>
            </SidebarGroup>
            <SidebarSpace/>
            <SidebarDivider/>
            <SidebarGroup
                label="Settings"
                icon={<UserSettingsSignInAvatar/>}
                to="/settings"
            >
                <SidebarSettings/>
            </SidebarGroup>
        </Sidebar>
        {children}
    </SidebarPage>
);
